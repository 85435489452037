<template>
  <div class="img-select" :style="isMore? 'width: 100%;':''" @click="onSwitchFunc">
    <div class="txt">{{imgTypeList[val] && imgTypeList[val].attrValue }}</div>
    <img src="../assets/images/icon-down.png" class="down" alt="" />
    <div class="options" :style="isMore ? 'transform:translateX(-50%);left: 50%' : '' " v-show="showOptions">
      <img src="../assets/images/select-top.png" class="top" alt="" />
      <div class="content">
        <div
          class="item"
          v-for="(item, key) in imgTypeList"
          :key="item.attrCode"
          @click.stop="selectOptionsFn(item)"
        >
          {{ item.attrValue }}
          <img
            src="../assets/images/icon-check.png"
            v-if="val === key"
            class="check"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Select',
  props: {
    selectVal: {
      type: [Number, String],
      default: 0
    },

    imgTypeList: {
      type: Array,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    },
    isMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOptions: false
    }
  },
  computed: {
    val: {
      get() {
        const index = this.imgTypeList.findIndex(
          (e) => this.selectVal === e.attrCode
        )
        console.log('getIndex', index)
        return index === -1 ? 0 : index
      }
    },
    onSwitchFunc() {
      return this.disabled ? () => {} : this.showOptionsFn
    }
  },
  mounted() {
    if (this.selectVal === 0) this.selectOptionsFn(this.imgTypeList[0])
    window.addEventListener('mouseup', () => {
      this.showOptions = false
    })
  },
  methods: {
    showOptionsFn() {
      this.showOptions = true
    },
    selectOptionsFn(item) {
      this.showOptions = false
      console.log(item)
      this.$emit('update:selectVal', item.attrCode)
    }
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', () => {
      this.showOptions = false
    })
  }
}

</script>
<style scoped>
.img-select {
  position: relative;
  width: 110px;
  height: 30px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #c9c9c9;
  margin-bottom: 10px;
  color: #000;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.img-select .txt {
  flex: 1;
  text-align: center;
}
.down {
  width: 24px;
  height: 24px;
}
.options {
  position: absolute;
  /* bottom: -186px; */
  top: 30px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.options .top {
  width: 16px;
  height: 8px;
  margin-bottom: -2px;
}
.content {
  width: 120px;
  max-height: 205px;
  background: #181818;
  color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.item {
  height: 45px;
  line-height: 45px;
  width: 100px;
  border-bottom: 1px solid #414141;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check {
  width: 24px;
  height: 24px;
}
</style>
