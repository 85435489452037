<template>
  <div class="lottery-container">
    <div class="lottery">
      <van-overlay :show="isOpen" @click="show = false">
        <div class="wrapper" @click.stop>
          <img
            :style="{
              width: '100%',
              height: 'auto',
              maxHeight: '88%',
            }"
            :src="activeImg"
          />

          <button class="button-go" @click="close">领取礼品</button>
          <div class="block" />
        </div>
      </van-overlay>
      <div
        class="lottery-box"
        :style="{
          width: calcSize(670, 'w'),
          height: calcSize(610, 'w'),
          left: calcSize(37, 'w'),
          top: calcSize(462, 'w'),
        }"
      >
        <LuckyWheel
          :width="calcSize(522, 'w')"
          :height="calcSize(522, 'w')"
          ref="myLucky"
          :blocks="blocks"
          :prizes="prizes"
          :buttons="buttons"
          @start="startCallBack"
          @end="endCallBack"
        />
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
<script>
/**
 *
 * 按需引入
 */
import { getQueryString } from "@/utils";
import { LuckyWheel, LuckyGrid } from "@lucky-canvas/vue";
import { startLotter, getUrlLink } from "@/api/home";
// 大转盘抽奖

export default {
  components: {
    LuckyWheel,
  },
  computed: {},
  data() {
    return {
      mobile: "",
      activeId: "",
      activeImg: "./static/cs.png",
      a: "",
      c: "",
      d: "",
      acId: "",
      channelId: "",
      round: "round-button",
      isOpen: false,
      isClick: true,
      tourl: "",
      buttons: [
        {
          radius: "50%",
          imgs: [
            {
              src: require("./static/zz.png"),
              width: "70%",
              top: "-140%",
            },
          ],
        },
      ],
      prizes: [{}, {}, {}, {}, {}, {}, {}, {}],
      blocks: [
        // { padding: "20px" },
        {
          //   padding: "20px",
          imgs: [
            {
              src: require("./static/bg.png"),
              width: "100%",
              rotate: true,
            },
          ],
        },
      ],
    };
  },
  created() {
    this.activityId = this.$route.query.activityId || "";
    this.mobile = this.$route.query.mobile || "";
    this.a = getQueryString("a") || "";
    this.c = getQueryString("c") || "";
    this.d = getQueryString("dsa") || "";
    this.channelId = getQueryString("channelId") || "";
    this.acId = getQueryString("acId") || "";
    if (!this.activityId || !this.mobile) {
      this.$toast("参数缺失，禁止访问！");
      this.isClick = false;
      return;
    }

  },
  methods: {
    // 适配 大小
    calcSize(num, type) {
      if (type == "w") {
        return (num / 750) * 100 + "vw";
      } else if (type == "h") {
        return (num / 1334) * 100 + "vh";
      }
    },
    close() {
      // this.isOpen = true; {{}

      // this.ToUrlLink()

       var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("在微信里");
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
              wx.miniProgram.navigateTo ({
                url: "/pagesA/my/discountCoupon/discountCoupon?type=syds", //跳转回小程序的页面
                success: function () {
                  console.log("success");
                },
                fail: function () {
                  console.log("fail");
                },
              });
             console.log("在小程序里", res);
          } else {
            console.log("不在小程序里", res);
                  if (this.tourl) {
                      window.location.href = this.tourl;
                    }
                    this.isOpen = false;
          }
        });
      } else {
        console.log("不在微信里");
          if (this.tourl) {
           window.location.href = this.tourl;
             }
           this.isOpen = false;
      }
    },
    ToUrlLink() {
      const linkParams = this.getUrlObj(decodeURIComponent(window.location.href));
      let params = {
        a: this.activityId,
        d: this.id,
        c: this.channelId,
        activityId: this.activityId,
        channelId: this.channelId,
        mobile: this.mobile,
        acId: this.acId,
        type: "syds", // 摄影大赛
        linkUrl: "/pagesA/my/discountCoupon/discountCoupon",
        linkCode:linkParams.linkCode
      };
      return getUrlLink(params)
        .then((res) => {
          console.log(res);
          return res.data.url_link;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    },
    setStyle() {
      return; // 测试完没问题，注释删除
      // document.getElementsByClassName(
      //   "van-dialog__footer"
      // )[0].style.background = "none";
      // document.getElementsByClassName("van-dialog__footer")[0].style.position =
      //   "absolute";
      // document.getElementsByClassName("van-dialog__footer")[0].style.top =
      //   this.calcSize(1102, "w");
      // document.getElementsByClassName("van-dialog__footer")[0].style.height =
      //   this.calcSize(76, "w");
      // document.getElementsByClassName(
      //   "van-goods-action-button"
      // )[0].style.background =
      //   "linear-gradient( 180deg, #FF9212 0%, #FE3407 100%)";
      // document.getElementsByClassName(
      //   "van-goods-action-button"
      // )[0].style.boxShadow = "0px -4px 8px 2px rgb(255 255 255 / 64%)";
    },
    async startCallBack() {
      // 当前抽奖中禁止二次点击
      if (!this.isClick) {
        return;
      }
      // 开始游戏抽奖

      let lotterData = await startLotter(this.activityId, this.mobile)
        .then((res) => {
          if(res.data.errno == 604){
            this.$toast(res.data.errmsg || '抽奖失败~');
            return false
          }else{
            return res.data.activityProduct;
          }

        })
        .catch((e) => {

          //  this.$toast("接口请求错误，请联系活动方进行反馈");
          return false;
        });

      if (lotterData) {
        this.$refs.myLucky.play();
        this.isClick = false;
        let _this = this;
        this.tourl = await this.ToUrlLink();
        console.log(this.tourl, "tourl");
        const index = lotterData.prizeStatus - 1; // 转盘索引是从0开始，后台数据从1开始、所以减一处理
        // 然后停止游戏 (缓慢停止)
        this.$refs.myLucky.stop(index);
        this.activeImg = lotterData.prizeText;
        setTimeout(() => {
          this.isOpen = true;
        }, 6000);
      }
    },
    endCallBack(prize) {
      // 当完全停止时, 触发回调函数
      console.log(prize);
    },
  },
  mounted() {
    // this.$refs.myLucky.play();
    // 修改弹框组件中的 确认按钮样式

     setTimeout(()=>{
       this.$nextTick(()=>{
        window.scrollTo(0,0);
      })
     },300)
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 80%;
  height: 90%;
  margin: 10% auto;
  // background:red;
  .button-go {
    width: 150px;
    height: 40px;
    border-radius: 20px;
    margin-top: 20px;
    color: white;
    text-align: center;
    font-weight: 600;
    background: linear-gradient(180deg, #ff9212 0%, #fe3407 100%);
    box-shadow: 0px -4px 8px 2px rgb(255 255 255 / 64%);
    border: none;
  }
}

.van-dialog {
  background: none;
  height: 100%;
  top: 50%;
}
#app {
  width: 100%;
  height: 100%;
  min-height: 672px;

.overlay {
  background: red;
}
.lottery-container {
  background-image: url(./static/dt-bar.png);
  width: 100%;
  height: 100vh;
  //  min-height: 672px;
}
  .lottery {
    width: 100%;
    height: 100%;

    // background-image: url(./static/dt.png);
    background-size: 100% auto;
    position: relative;
    background-repeat: no-repeat;
    .image-jp {
      position: relative;
    }
    //   background-repeat: no-repeat;
    .lottery-box {
      position: absolute;
      background-image: url(./static/dp.png);
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background-size: 100%;
    }
  }
}
</style>
