<template>
  <div v-if="show">
    <div class="shade"></div>
    <div class="content" @click="success">
      <img src="../assets/images/success1.png" alt="" class="success">
    </div>
  </div>
</template>
<script setup>
export default {
  name: 'Success',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    success() {
      this.$emit('success')
    }
  }
}
</script>

<style scoped>
.shade {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9;
  overflow: hidden;
}
.content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.success{
  width: 320px;
  height: 307px;
}
</style>
