import request from '@/utils/request'

// 获取验证码
export function getVerifyCaptcha(data) {
  return request({
    url: '/wx/captcha/get',
    method: 'post',
    data
  })
}

// 验证滑块验证码
export function getVerifyCaptchaCheck(data) {
  return request({
    url: '/wx/captcha/check',
    method: 'post',
    data
  })
}
