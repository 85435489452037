import axios from 'axios'
import {
  Toast
} from 'vant'

const baseURL = process.env.VUE_APP_BASE_API || ''

// create an axios instance
const service = axios.create({
  baseURL,
  headers: {
    // 'Content-Type': 'multipart/form-data; charset=UTF-8',
    'Content-Type': 'application/json; charset=UTF-8'
  },
  timeout: 300000 // request timeout
})

service.interceptors.request.use(
  config => {
    if (config.method.toLowerCase() === 'get') {
      config.params = config.params || {}
      config.params.t = new Date().getTime()
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const data = response.data
    // 604状态码是摄影大赛后台新增状态
    if (data.errno === 0 || data.errno === 604) {
      if (data.errno === 0) {
        return response.data
      } else {
        return response
      }
    } else {
      // console.log(response,'8888888888')
      if (response.data.errmsg) {
        Toast(response.data.errmsg)
      }

      return Promise.reject(response.data)
    }
  },
  err => {
    let errMsg = '请求错误'
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          errMsg = `错误代码：400 - 请求体错误`
          break
        case 401:
          errMsg = `错误代码：401 - 身份认证失败`
          break
        case 403:
          errMsg = `错误代码：403 - 用户没有权限`
          // router.push(`/403`);
          break
        case 404:
          errMsg = `错误代码：404 - 接口不存在`
          // router.push(`/404`);
          break
        case 500:
          errMsg = `错误代码：500 - 服务器错误`
          break
        case 502:
          errMsg = `错误代码：502 - 服务器维护中`
          break
        case 503:
          errMsg = `错误代码：503 - 服务不可用`
          break
      }
    }

    Toast(errMsg)
    return Promise.reject(err)
  }
)

export default service
