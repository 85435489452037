const BigNumber = require('bignumber.js')
BigNumber.config({
  ROUNDING_MODE: 1
})

export default {
  multiplied(arg1, arg2, fixed = 2) {
    const number = new BigNumber(arg1)
    const number2 = new BigNumber(arg2)
    return number.multipliedBy(number2).toFixed(fixed)
  },
  toFixed(arg, fixed = 2) {
    const number = new BigNumber(arg)
    return number.toFixed(fixed)
  }
}
