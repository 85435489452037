<template>
 <CommonDialog :value="isShow">
 <template>
   <div class="upload-Dialog-wrapper">
    <div class="container">
      <header class="content_header">
        <div class="title">上传预览</div>
        <span class="description">{{ getDescription }}</span>
      </header>

      <section class="content_imglist" v-if="isInconformityImageList">
        <div class="img-title">以下图片大小不符，无法上传！</div>

        <div class="img-wrapper">
          <div class="img-item inconformity" v-for="(inconformityImage, index) in inconformityImageList" :key="index">
            <img :src="inconformityImage.imgUrl" alt="" class="object-cover">
          </div>
        </div>
      </section>

      <section class="content_imglist" v-if="isAccordantImageList">
        <div class="img-title">待上传照片</div>
        <div class="img-wrapper">
          <div class="img-item" v-for="(accordantImage, index) in accordantImageList" :key="index">
            <img :src="accordantImage.imgUrl" alt="" class="object-cover">
            <van-icon name="clear" class="btn-close" @click.stop="onDel(index)" />
          </div>
        </div>
      </section>

      <section class="content_num">
        本次待上传
        <span style="color: var(--color)">{{ accordantImageNum }}</span>
        张，剩余可上传
        <span  style="color: var(--color)">{{ uploadImageSurplusNum }}</span>
        张。
      </section>

    </div>

     <footer class="footer">
        <van-button type="default" class="btn" :class="{'auto-width': !isAccordantImageList}" @click="cancel">取消</van-button>
        <van-button type="default" :disabled="isUploadBtnDisabled" color="#181818" class="btn" v-if="isAccordantImageList" @click="onUpload">上传</van-button>
      </footer>
   </div>
 </template>
 </CommonDialog>
</template>

<script>
import CommonDialog from '@components/Dialog/CommonDialog'

export default {
  name: 'AwaitUploadDialog',

  components: { CommonDialog },

  props: {
    accordantImageList: {
      type: Array,
      default: () => []
    },

    inconformityImageList: {
      type: Array,
      default: () => []
    },

    detail: {
      type: Object,
      default: () => {}
    },

    imgListNum: {
      type: Number,
      default: 0
    },

    getImgSize: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      isShow: false
    }
  },

  computed: {
    isAccordantImageList() {
      return this.accordantImageList.length > 0
    },

    isInconformityImageList() {
      return this.inconformityImageList.length > 0
    },

    accordantImageNum() {
      return this.accordantImageList.length
    },

    uploadImageSurplusNum() {
      return this.detail.photoUploadMax - this.imgListNum
    },

    isUploadBtnDisabled() {
      return this.accordantImageNum > this.uploadImageSurplusNum
    },

    getDescription() {
      const { photoSizeMax, photoSizeMin } = this.detail

      return `（单次最多上传9张；图片大小要求：${photoSizeMin}MB-${photoSizeMax}MB）`
    }

  },

  methods: {
    open() {
      this.isShow = true
    },

    close() {
      this.isShow = false
    },

    cancel() {
      this.close()
      this.onInitAwaitListData()
    },

    onInitAwaitListData() {
      this.$emit('initAwaitList')
    },

    onDel(index) {
      this.accordantImageList.splice(index, 1)
    },

    onUpload() {
      // this.cancel()
      this.close()
      this.$emit('upload', this.accordantImageNum)
    }
  }
}
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar

{

    width:5px;

    height: 5px;

    background-color:#F5F5F5;

}

/*定义滚动条轨道

 内阴影+圆角*/

::-webkit-scrollbar-track

{

    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);

    border-radius:10px;

    background-color:#F5F5F5;

}

/*定义滑块

 内阴影+圆角*/

::-webkit-scrollbar-thumb

{

    border-radius:10px;

    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);

    background-color:#555;

}
.object-cover {
  object-fit: cover;
}
.upload-Dialog-wrapper {
  position: relative;
  background-color: #FFFFFF;
  width: 320px;
  max-height: 544px;
  border-radius: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  --color: #D60015;
  padding-bottom: 60px;

  .container {
    padding: 16px 15.9px 20px 16px;
    height: 100%;
    overflow: auto;

    .content {

      &_header {
        text-align: center;
        .title {
          color: var(--color);
          font-size: 16px;
        }

        .description {
          font-size: 12px;
          color: #787878;
        }
      }

      &_imglist {
        padding-top: 20px;

        .img-title {
          color: var(--color);
          font-size: 14px;
        }
        .img-wrapper {
          display: flex;
          flex-wrap: wrap;
          max-height: calc(100vh - 190px);
          overflow: auto;
          .img-item {
            position: relative;
            width: 30%;
            height: 88px;
            border-radius: 4px;
            overflow: hidden;
            margin-right: 12px;
            margin-top: 12px;

            &:nth-child(3n) {
              margin-right: 0;
            }

            &.inconformity {
              border: 1px solid var(--color);
              filter: contrast(0.5);
            }

            .btn-close {
              position: absolute;
              top: 2px;
              right: 2px;
              font-size: 16px;
            }

            img {
              width: 100%;
              height: 100%
            }
          }
        }
      }

      &_num {
        margin-top: 8px;
        font-size: 12px;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 20px;
    left: 16px;
    right: 16px;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 138px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #000000;
      &.auto-width {
        width: 100%;
      }
    }
  }
}
</style>
