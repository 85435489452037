/* 首页模块管理 */
import axios from 'axios'
import request from '@/utils/request'

// 上传图片的地址
const uploadImageBaseUrl = process.env.VUE_APP_FILE_API

// 获取活动信息
export function getActiveDetail(data) {
  return request({
    url: '/wx/activityPhotography/select',
    method: 'get',
    params: data
  })
}

// 修改图片名称

export function ChangeName(data) {
  return request({
    url: '/wx/refund/updateAvatar',
    method: 'post',
    data
  })
}

//  查询活动是否开启抽奖
export function queryLotterStatus(data) {
  return request({
    url: '/wx/activityPhotography/roulette/check/' + data,
    method: 'get'
  })
}
//  抽奖接口
export function startLotter(activityId, mobile) {
  return request({
    url: '/wx/activityPhotography/roulette/go/' + activityId + '/' + mobile,
    method: 'get'
  })
}

// 提交活动信息
export function submitActiveInfo(data) {
  return request({
    url: '/wx/activityPhotography/createNew',
    method: 'post',
    data

  })
}

// 获取挑战url小程序
export function getUrlLink(data) {
  return request({
    url: '/wx/activityPhotography/getUrlLink',
    method: 'post',
    data
  })
}

// 获取挑战url小程序（通用）
export function getCommonUrlLink(data) {
  return request({
    url: '/admin/componentPage/getUrlLink', // /wx/activityPhotography/getCommonUrlLink
    method: 'post',
    data
  })
}

// 验证码
export function getCaptcha(data, params) {
  return request({
    url: '/wx/auth/regCaptcha',
    method: 'post',
    data,
    headers: { 'X-Dts-Captcha': JSON.stringify(params) }

  })
}

export function getActivityPhotographyUserImg(params) {
  return request({
    url: '/wx/activityPhotography/getActivityPhotographyUserImg',
    method: 'GET',
    params
  })
}

export function ossSignFile(params) {
  return axios({
    method: 'get',
    url: `${uploadImageBaseUrl}/file/ossSignYan`,
    params: params
  })
}

