import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vant from 'vant'
// import { Uploader } from 'vant';
import 'vant/lib/index.css'
import math from './utils/math.js'
import {
  parseUA
} from './utils/index'
// import {
// 	getWxConfig
// } from './api/config'
import wx from 'weixin-js-sdk'
import { Toast, Uploader, Button, Popup, Dialog, Overlay, Radio, RadioGroup, Field, Icon } from 'vant'
import { parseTime } from './utils/index.js'
Vue.use(Toast)
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(Field)
Vue.use(RadioGroup)
Vue.use(Overlay)
Vue.use(Icon)

Vue.config.productionTip = false
Vue.prototype.$math = math
Vue.prototype.$store = store
Vue.prototype.$wx = wx
Vue.prototype.$time = parseTime

const wxConfig = url => {
  getWxConfig({
    strUrl: url
  }).then(res => {
    wx.config({
      debug: true,
      appId: res.data.appId,
      timestamp: res.data.timestamp,
      nonceStr: res.data.nonceStr,
      signature: res.data.signature,
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage'
      ] // 必填，需要使用的JS接口列表
    })
  })
}

// 路由钩子配置微信jssdk相关配置
// router.afterEach(function(to) {
// 	let _url = window.location.href
// 	if (!sessionStorage.getItem('initLink')) {
// 		sessionStorage.setItem('initLink', _url)
// 	}
// 	// 非ios设备，切换路由时候进行重新签名
// 	if (parseUA().ios !== true) {
// 		setTimeout(() => {
// 			wxConfig(_url)
// 		}, 50)
// 	} else {
// 		setTimeout(() => {
// 			wxConfig(sessionStorage.getItem('initLink'))
// 		}, 50)
// 	}
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
