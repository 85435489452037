var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lottery-container"},[_c('div',{staticClass:"lottery"},[_c('van-overlay',{attrs:{"show":_vm.isOpen},on:{"click":function($event){_vm.show = false}}},[_c('div',{staticClass:"wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{style:({
            width: '100%',
            height: 'auto',
            maxHeight: '88%',
          }),attrs:{"src":_vm.activeImg}}),_c('button',{staticClass:"button-go",on:{"click":_vm.close}},[_vm._v("领取礼品")]),_c('div',{staticClass:"block"})])]),_c('div',{staticClass:"lottery-box",style:({
        width: _vm.calcSize(670, 'w'),
        height: _vm.calcSize(610, 'w'),
        left: _vm.calcSize(37, 'w'),
        top: _vm.calcSize(462, 'w'),
      })},[_c('LuckyWheel',{ref:"myLucky",attrs:{"width":_vm.calcSize(522, 'w'),"height":_vm.calcSize(522, 'w'),"blocks":_vm.blocks,"prizes":_vm.prizes,"buttons":_vm.buttons},on:{"start":_vm.startCallBack,"end":_vm.endCallBack}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }