/* 获取微信配置管理 */
import request from '@/utils/request'
import axios from 'axios'

// 获取微信配置
export function getWxConfig(data) {
  return request({
    url: '/wx/activityPhotography/signature',
    method: 'get',
    params: data
  })
}

// 埋点统计
export function saveUserChannelInfoById(data) {
  return request({
    url: '/wx/cache/saveUserChannelInfoById',
    method: 'post',
    data
  })
}
//  上传接口
export function uploadImage(data) {
  return axios.request({
    url: process.env.VUE_APP_UPLOAD_API + '/wx/refund/avatar',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8'
    }
  })
}
