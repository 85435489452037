<template>
  <div class="home norem-home">
    <AwaitUploadDialog
      ref="awaitUploadDialog"
      :accordantImageList="accordantImageList"
      :inconformityImageList="inconformityImageList"
      @initAwaitList="onInitAwaitList"
      :detail="detail"
      :imgListNum="getImgListNum"
      :getImgSize="getImgSize"
      @upload="onUpload"></AwaitUploadDialog>
    <UploadLoading ref="uploadLoading" :uploadingInfo="uploadingInfo"></UploadLoading>

    <LogVerify ref="logVerify" :mobile="mobile" @getCaptcha="onGetCaptcha" />

    <div v-if="errmsg" class="erroMsg">
      <img src="./static/djs.png" width="100px" alt="">
      <h1>
        {{ errmsg }}
      </h1>
    </div>
    <!-- 页面展示 -->
    <div v-else>
      <img class="home-banner" :src="detail.activityHeadPicture" alt="" />
      <!-- 上传按钮 -->
      <div class="flex-c-center" v-if="detail.uploadImgStatus === 0">
        <div class="upload" @click="handleFile">
          <img src="./static/icon-upload.png" class="" />上传作品
          <input @change="uploadFiles" multiple type="file" accept="image/jpg, image/jpeg, image/png" ref="file" />
        </div>
        <div class="dsc">
          <div class="ysc">
            已上传<span>{{ imgList.length }}/{{ uploadImageNum }}</span>张
          </div>
          <div class="login" @click="lookMyWork" v-if="isShow">查看历史作品</div>
        </div>
      </div>
      <!-- 上传按钮 END -->
      <!-- 上传列表 -->
      <van-radio-group
        v-model="photoStatus"
        direction="horizontal"
        class="my-radio"
        v-if="imgShowStatus == 2 && imgList.length > 0">
        <van-radio :name="0" checked-color="#D60015">单图</van-radio>
        <van-radio :name="1" checked-color="#D60015"> 组图</van-radio>
      </van-radio-group>
      <!-- 单图显示 -->
      <img-list v-if="photoStatus === 0" :list="imgList" :imgTypeList="imgTypeList" :imgInfoList="imgInfoList">
      </img-list>
      <!-- 组图显示 -->
      <img-list1
        v-if="photoStatus === 1"
        :list="imgList"
        @updatePhotoType="updatePhotoType"
        :imgTypeList="imgTypeList"
        :imgInfoList="imgInfoList"></img-list1>
      <!-- 表单 -->
      <div class="home-content__form flex-c-center">
        <div class="home-content__formitem" v-for="(item, index) in activityFillings" :key="index">
          <div class="home-content__formlabel">{{ item.label }}</div>
          <input
            v-model="item.value"
            :disabled="isDisabled"
            class="home-content__forminput"
            type="text"
            :placeholder="item.place || '请输入'"
            maxlength="50">
        </div>

        <div class="home-content__formitem" v-if="isShowWorkDesc">
          <div class="home-content__formlabel">简介（最多1000字）</div>
          <textarea
            :maxlength="1000"
            class="home-content__forminput input_textarea"
            @input="onInput"
            name="textarea"
            :placeholder='workDescPlaceholder'
            style="resize: vertical"></textarea>
        </div>

        <div class="home-content__formitem">
          <div class="home-content__formlabel">手机号</div>
          <input
            v-model="mobile"
            :disabled="isDisabled"
            class="home-content__forminput"
            maxlength="11"
            type="text"
            placeholder="请输入手机号">
        </div>

        <div class="home-content__formitem">
          <div class="home-content__formlabel">验证码</div>
          <div class="home-content__sendcode">
            <input
              v-model="code"
              :disabled="isDisabled"
              class="home-content__code"
              maxlength="6"
              type="text"
              placeholder="请输入验证码">
            <div class="home-content__code-btn" v-if="sendStatus === 0" @click.stop="getCode">
              获取验证码
            </div>
            <div class="home-content__code-btn home-content__code-btn-dis" v-if="sendStatus === 1">
              发送中({{ seconds }}s)
            </div>
            <div class="home-content__code-btn" v-if="sendStatus === 2" @click.stop="getCode">
              重新发送
            </div>
          </div>
        </div>

        <div style="align-self: flex-start;">
          <input class="radioInput" id="radioInput" type="radio" :checked='checked' @click="clickCheck" />
          <label for="radioInput" class="radioMsg">提交报名将默认成为Aphoto雅昌影像会员</label>
        </div>

        <van-button
          v-if="detail.status === '1'"
          class="home-content__btn"
          round
          color="#D82E4A"
          size="normal"
          block
          @click="handleSubmit">
          提交报名
        </van-button>

        <!-- :disabled="!isFilled" -->

        <van-button
          v-if="detail.status === '0'"
          class="home-content__btn"
          round
          type="default"
          size="normal"
          block
          disabled>
          活动尚未开始
        </van-button>

        <van-button
          v-if="detail.status === '2'"
          class="home-content__btn"
          round
          type="default"
          size="normal"
          block
          disabled>
          活动已经结束
        </van-button>
        <div class="desc1" v-if="detail.uploadImgStatus === 0">{{ detail.activityEndTime }}前可提交新的作品，已提交的作品不可删除</div>
        <div class="desc1" v-else>活动截止时为{{ detail.activityEndTime }}</div>
        <div class="home-content__carousel" @click="commitToWx">
          <img src="./static/photo.png" alt="" style="width: 100%" />
        </div>
      </div>
      <!-- 表单end -->

      <!-- 底部html -->
      <div class="home-content__detail" v-html="detail.activityContent"></div>
    </div>
    <van-popup v-model="showCouponToMini" position="bottom" :style="{ height: '20vh' }">
      <div class="popCon">
        <div class="tip">{{ btnDetail.title }}</div>
        <div style="display:flex; justify-content: center;">

          <button class="btn-go-home" @click="toHome">{{ btnDetail.btnName }} </button>

        </div>
      </div>
    </van-popup>
    <!-- <button @click="lottery"> 抽奖 </button> -->
    <login-phone :activity-id="id" :show="isShowLogin" @close="isShowLogin = false" @success="returnData" />
    <success :show="isSuccess" @success="successTo"></success>
    <div class="supernatant-bg" v-if="isSupernatantBg">
      <img class="close-supernatant-bg" src="../views/static/close-supernatant-bg@2x.png" alt=""
           @click="closeSupernatantBg"
      >
      <img class="supernatant-bg-result" :src="popoverImageUrl" alt="">
    </div>
  </div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
<script>
import {nanoid} from 'nanoid';
import axios from 'axios'
import {
  getActiveDetail,
  submitActiveInfo,
  getCaptcha,
  getUrlLink,
  queryLotterStatus,
  ChangeName,
  getCommonUrlLink,
  ossSignFile
} from "@/api/home";
import { uploadImage, getWxConfig,saveUserChannelInfoById } from "@/api/config";

import isPc from "@/utils/isPc"
import { getQueryString } from "@/utils";
import request from "@/utils/request";

import ImgList from "@/components/ImgList"
import ImgList1 from "@/components/ImgList1"
import Success from "@/components/Success"
import LoginPhone from '@/components/LoginPhone'
import AwaitUploadDialog from '@/components/UploadImage/AwaitUploadDialog'
import UploadLoading from '@/components/UploadImage/UploadLoading'
import LogVerify from '@/components/LogVerify/LogVerify'

export default {
  name: "Home",
  components:{
    ImgList,
    ImgList1,
    Success,
    LoginPhone,
    AwaitUploadDialog,
    UploadLoading,
    LogVerify
  },
  data() {
    return {
      setNum:0,
      isSupernatantBg:false,
      popoverImageUrl:"",
      showCouponToMini: false,
      errmsg: "",
      signatureInfo: {},
      id: "", // '20210629204405103526'
      channel_code: "",
      channel_name: "",
      acId: "",
      activity_name: "",
      activityId: "",
      detail: {},
      btnDetail: {
        title: "报名成功！欢迎定制您的作品",
        path: "pages/index/index",
        btnName: "立即定制",
      },
      activityFillings: [],
      fileList: [],
      mobile: "",
      code: "",
      timer: null,
      uploadImgStatus: 0, // '上传图片开关 0 开 1关'
      imgShowStatus: 0, //0单图、1图组、3单图+图组图片类似
      photoStatus: 0, // 0单组、1多组
      imgsWorkGroup: "", // 图组
      disabledGroupName: false,
      seconds: 60,
      sendStatus: 0, // 0-待发送  1-发送中 2-重新发送,
      a: "",
      c: "",
      d: "",
      channelId: "",
      checked: true,
      isPreview: true,
      activeImgDataIndex: null,
      worksDisabled: false,
      submitImgUrls: [],
      imgList:[],
      isShow:true,
      imgTypeList:[],
      imgInfoList:[],
      photoType:'',
      isSuccess:false,
      applyId:'',
      userId:'',
      isShowLogin:false,
      workDescValue: '',
      photoNumber:"",// 报名商品配置得打印数量
      accordantImageList: [], // 上传图片时，符合的图片列表
      inconformityImageList: [],  // 不符合的图片列表
      uploadingInfo: { accordantImageNumber: 0, uploaded: 0 },
      isUploadBtnDisabled: false,
    };
  },
  computed: {
    isFilled() {
      if (
        !this.fileList.length ||
        this.activityFillings.some((v) => !v.value) ||
        !this.isMobile() ||
        !this.isCode()
      ) {
        return false;
      } else {
        return true;
      }
    },
    // 未进行的状态全部禁止输入
    isDisabled() {
      return this.detail.status !== "1";
    },
    applyStatus(){
       return this.detail.applyStatus || 0
    },
    isShowWorkDesc() {
      return !this.detail.worksDescStatus && this.detail.uploadImgStatus === 0
    },

    isWorkDescRequired() {
      return !this.detail.worksDescCheck
    },

    workDescPlaceholder() {
      return this.detail.worksDesc || '请输入'
    },

    uploadImageNum() {
      return this.detail.photoUploadMax || 150
    },

    getImgListNum() {
      return this.imgList.length || 0
    },

    getImgSize() {
      const { photoSizeMax, photoSizeMin } = this.detail;
      const MIN_SIZE = Number(photoSizeMin || 0) * 1024 * 1024;
      const MAX_SIZE = Number(photoSizeMax || 30) * 1024 * 1024;
      return {MIN_SIZE, MAX_SIZE}
    },

    // isUploadBtnDisabled() {
    //   return !!this.$refs.file.value
    // }

  },
  created() {
    // this.getWxConfig()
  },
  watch: {
    uploadType(newValue) {
      console.log("newValue", newValue);
      this.setImgStyle(newValue);
    },

    fileList(newValue) {
      console.log("newValue----", newValue);
    },

    'uploadingInfo.uploaded'(value) {
      if (this.uploadingInfo.accordantImageNumber === value) {
        this.onUploaded()
      }
    }
  },
  mounted() {
    try {
      this.id = getQueryString("id") || "";
    } catch (error) {
      this.$toast("参数缺失，禁止访问！");
      this.errmsg = "参数缺失!";
      return;
    }

    this.channel_code = getQueryString("channel_code") || "";
    this.channel_name = getQueryString("channel_name") || "";
    this.activity_name = getQueryString("activity_name") || "";
    this.a = getQueryString("a") || "";
    this.c = getQueryString("c") || "";
    this.d = getQueryString("d") || "";
    this.channelId = getQueryString("channelId") || "";
    this.acId = getQueryString("acId") || "";
    // this.getWxConfig();
    this.getActiveDetail();
    // this.wx_click_allow()
  },
  methods: {
    closeSupernatantBg(){
      this.isSupernatantBg = false;
    },
    onInput(event) {
      if (event.target.value === this.workDescValue) return;
      this.workDescValue = event.target.value
    },

    onUploaded() {
      this.$toast('上传成功')
      this.$refs.uploadLoading.close()
      this.onInitAwaitList()
      this.setUploadedNumber(0)
      this.isUploadBtnDisabled = false
    },

    onInitAwaitList() {
      this.accordantImageList = []
      this.inconformityImageList = []
      this.$refs.file.value = ""
    },

    returnData(result){
      this.isShowLogin = false;
      const keyString = 'ABCDEFGHIJ'
      // 赋值表单数据
      this.activityFillings = this.activityFillings.map((item,index)=>{
        item.value = result['activityFilling'+keyString[index]+keyString[index]]
        return item
      })
      // 赋值手机号
      this.mobile = result['activityFillingJJ'] || ''
      // 隐藏查看历史作品按钮
      this.isShow = false
      let photoStatus = this.photoStatus
      // 赋值图片列表
      this.imgList = result.photoTypeList.map((item,index)=>{
        let obj = {};
        if(photoStatus == 0){
          // 单图
          obj['image'] = item.image;
          obj['id'] = item.id
          obj.photoType = item.photType;
          let imgInfoList = JSON.parse(JSON.stringify(this.imgInfoList))
          imgInfoList = imgInfoList.map((imgInfo,index1)=>{
            imgInfo.value = item['photoDesc'+keyString[index1]+ keyString[index1]]
            return imgInfo
          })
          obj.imgInfoList = imgInfoList
        }else{
          // 组图
          obj['image'] = item.image;
          obj['id'] = item.id
          obj.photoType = item.photType
          this.imgInfoList = this.imgInfoList.map((imgInfo,index1)=>{
            imgInfo.value = result['photoDesc'+keyString[index1]+ keyString[index1]]
            return imgInfo
          })
        }
        return obj;
      })
    },

    lookMyWork() {
      this.isShowLogin = true
    },

    updatePhotoType(val){
      this.photoType = val
    },

    handleFile(){
      if (this.isUploadBtnDisabled) return
      this.$refs.file.click()
    },

    getUploadInfo(file) {
      const imgUrl = URL.createObjectURL(file)
      return {imgUrl, file}
    },

    uploadFiles(e){
      const files = [...e.target.files].slice(0, 9)

      const { MIN_SIZE, MAX_SIZE} = this.getImgSize

      for (const file of files) {
        const info = this.getUploadInfo(file)

        if (file.size < MIN_SIZE || file.size > MAX_SIZE) {
          this.inconformityImageList.push(info)
          continue
        }

        this.accordantImageList.push(info)
      }

      this.$refs.awaitUploadDialog.open()

    },

    // js 动态修改图片预览框间距

    onUpload(number) {
      this.uploadingInfo.accordantImageNumber = number
      this.$refs.uploadLoading.open()
      this.isUploadBtnDisabled = true
      this.accordantImageList.forEach((e) => {
        console.log(e);
        this.uploadImage(e.file)
      })
    },

    setUploadedNumber(number) {
      this.uploadingInfo.uploaded = number
    },

    async uploadImage(file) {
      const oss = await ossSignFile({ type: 2 })
      this.ossUpload({ file, oss: oss.data.data })
    },

    ossUpload({ file, oss, batchId }) {
      const filename = file.name
      // base64 - 解码
      let callback = JSON.parse(atob(oss.callback))
      // 编码
      let newcallback = btoa(JSON.stringify(callback))
      let formData = new FormData()
      // 随机生成字符串
      let newfilename = Math.random().toString(36).substr(2) + '.' + filename.split('.')[1]
      formData.append('key', oss.dir + newfilename)
      formData.append('OSSAccessKeyId', oss.accessId) // accessKeyIdz
      formData.append('policy', oss.policy) // policy
      formData.append('Signature', oss.signature) // 签名
      formData.append('callback', newcallback) // 回调
      formData.append('success_action_status', 200) // 成功后返回的操作码
      // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
      formData.append('file', file)
      axios
        .post(oss.host, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => {
          if (res.data.Status === 'OK') {
             const imgObj = {
              image: res.data.imgPath,
              photoType: "",
              photoDesc: "",
            }
            let imgInfoList = JSON.parse(JSON.stringify(this.imgInfoList))
            imgInfoList.map(v=> v.value = '')
            imgObj.imgInfoList =  imgInfoList
            this.imgList.push(imgObj)
          } else {
            this.$toast(res.data.errmsg)
          }
        })
        // 上传失败
        .catch((err) => {
          this.$toast("上传失败")
          this.$toast.clear();
        })
        .finally(() => {
          const num =  this.uploadingInfo.uploaded + 1
          this.setUploadedNumber(num)
        })
    },

    setImgStyle(newValue) {
      let doms = document.getElementsByClassName("van-uploader__preview");
      let length = doms.length;
      if (!doms) {
        return;
      }
      if (newValue == 2 || this.imgShowStatus == 0) {
        this.fileList.forEach((item, i) => {
          document.getElementsByClassName("van-uploader__preview")[
            i
          ].style.margin = "0 0.21333rem 0.91333rem 0";
        });
      } else {
        this.fileList.forEach((item, i) => {
          document.getElementsByClassName("van-uploader__preview")[
            i
          ].style.margin = "0 0.21333rem 0.21333rem 0";
        });
      }
    },
    clickPreview(data, i) {
      this.isPreview = true;
      console.log("data", data, i);
      this.activeImgDataIndex = i.index;
    },
    // 图组名称保存--废弃
    imgChangNameGroup(warning) {
      let Value = this.imgsWorkGroup;
      console.log("valiue-----", Value, warning);
      if (!warning) {
        // alert(2)
        if (!Value.length || !this.fileList.length) {
          // 如果没有上传图片不进行改名请求
          // alert(1)
          return;
        }

        if (!Value.length) {
          this.$toast("作品名称不能为空");
          return;
        }
        if (!this.fileList.length) {
          this.$toast("请上传作品");
          return;
        }
      }
      let newArr = [];
      let _this = this;
      this.fileList.forEach((item) => {
        newArr.push(item.url);
      });

      console.log("newArr", newArr, Value);
      let strings = newArr.join(",");
      this.$toast.loading({
        message: "作品名保存中...",
        forbidClick: true,
        duration: 0,
        loadingType: "spinner",
      });
      ChangeName({ fileName: Value, oldUrlList: strings })
        .then((res) => {
          console.log("imgChangNameGroup", res);
          res.data.url.forEach((item, i) => {
            _this.fileList[i].url = item;
            _this.fileList[i].file.value = "";
          });
          this.$toast.clear();
        })
        .catch((res) => {
          this.$toast.clear();
        });
    },
    // 单张图片保存 -- 废弃
    async imgChangName(data, e) {
      let Value = e.target.value.replace(/\s+/g, "");
      if (!Value.length && !this.fileList.length) {
        // 如果没有上传图片不进行改名请求
        return;
      }
      if (!Value.length) {
        this.$toast("作品名称不能为空");
        return;
      }

      let Index = this.fileList.findIndex((item) => {
        return item.file.key == data.key;
      });
      console.log("[Index]" + "---" + Index);
      let url = this.fileList[Index].url;
      let _this = this;
      this.$toast.loading({
        message: "作品名保存中...",
        forbidClick: true,
        duration: 0,
        loadingType: "spinner",
      });
      this.worksDisabled = true;

      let resData = await ChangeName({
        fileName: e.target.value,
        oldUrlList: url,
      })
        .then((res) => {
          return res;
        })
        .catch((res) => {
          this.$toast.clear();
          return null;
        });

      if (resData) {
        _this.fileList[Index].url = resData.data.url[0];
        console.log("[Index]" + "---" + Index, data, this.fileList[Index].url);
        this.worksDisabled = false;
        this.$toast.clear();
      }
      console.log("resData", resData);
      // this.isPreview= true
    },
    ToUrlLink() {
      const linkParams = this.getUrlObj(decodeURIComponent(window.location.href));
      let params = {
        a: this.id || this.acId,
        acId: "",
        activityId: this.id || this.acId,
        c: "",
        channelId: "",
        linkUrl: "/pages/index/index",
        mobile: this.mobile,
        type: "syds",
        linkCode:linkParams.linkCode
      };
      return getUrlLink(params)
        .then((res) => {
          console.log(res);
          return res.data.url_link;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    },

    async getToCommitHomeInfo(link) {
      console.log({linkUrl: link});
      const wxJumpLink = await getCommonUrlLink({link}) //linkUrl
      return {urlLink: wxJumpLink.data.url_link, url: link}
    },
    getUrlObj(url) {
      let urlObject = {};
      if (/\?/.test(url)) {
        let urlString = url.substring(url.indexOf('?') + 1);
        let urlArray = urlString.split('&');
        for (var i = 0, len = urlArray.length; i < len; i++) {
          let urlItem = urlArray[i];
          let item = urlItem.split('=');
          urlObject[item[0]] = item[1];
        }
        return urlObject;
      }
    },
    async commitToWx() {
      const linkParams = this.getUrlObj(decodeURIComponent(window.location.href));
      let link = `/pages/index/index?linkCode=${linkParams.linkCode || ''}`;
      const info = await this.getToCommitHomeInfo(link)
      this.wxJumpTO(info)
    },

    async toHome() {
      let tourl = await this.ToUrlLink();
      this.wxJumpTO({urlLink: tourl, url: '/pages/index/index'})
    },

    wxJumpTO(toUrlInfo) {
      console.log(toUrlInfo);
      const ua = navigator.userAgent.toLowerCase();
      const tourl = toUrlInfo.urlLink
      console.log("tourl",tourl)
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("在微信里");
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            wx.miniProgram.switchTab({
              url: toUrlInfo.url, //跳转回小程序的页面
              success: function () {
                console.log("success");
              },
              fail: function () {
                console.log("fail");
              },
            });
            console.log("在小程序里", res);
          } else {
            console.log("不在小程序里", res);
            if (tourl) {
              console.log("tourl", tourl);
              window.location.href = tourl;
            } else {
              this.$toast("跳转失败，请稍后重试！");
            }
          }
        });
      } else {
        console.log("不在微信里");
        if (tourl) {
          console.log("tourl", tourl);
          window.location.href = tourl;
        } else {
          this.$toast("跳转失败，请稍后重试！");
        }
      }
    },

    lottery() {
      // return;
      let activityId = this.id || this.acId;
      let mobile = this.mobile || "";
      let { a, c, d, acId, channelId } = this;
      this.$router.push({
        path: "/lottery",
        query: {
          activityId,
          mobile,
          a,
          c,
          d,
          acId,
          channelId,
        },
      });
    },
    clickCheck() {
      if (this.checked) {
        this.checked = false;
      } else {
        this.checked = true;
      }
      console.log("=========clickCheck=============");
    },
    // 微信授权点击允许事件
    wx_click_allow() {
      var btn = document.getElementById("launch-btn");
      btn.addEventListener("launch", function (e) {
        console.log("success");
        window._dgt.push([
          "trackEvent",
          "shop_coupon_jump",
          ["d_view_page"],
          [this.mobile],
        ]);
        window._dgt.push(["trackAttr", ["channel_code"], [this.channel_code]]);
        window._dgt.push(["trackAttr", ["channel_name"], [this.channel_name]]);
        window._dgt.push(["trackAttr", ["activity_code"], [this.id]]);
        window._dgt.push([
          "trackAttr",
          ["activity_name"],
          [this.activity_name],
        ]);
        // alert('点击允许')
      });
      btn.addEventListener("error", function (e) {
        // alert('点击取消')
        console.log("fail", e.detail);
      });
    },
    isMobile() {
      const flag = /^1[0-9]{10}$/.test(this.mobile);
      return flag;
    },
    isCode() {
      const flag = /^[0-9]{6}$/.test(this.code);
      return flag;
    },
    startCountDown() {
      if (this.timer) return;
      this.sendStatus = 1;
      this.timer = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--;
        } else {
          this.clearTimer();
        }
      }, 1000);
    },

    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
      this.seconds = 60;
      this.sendStatus = 2;
    },

    getCode() {
      if (this.isMobile()) {
        this.onHandleLogVerify()
      } else {
        this.$toast("请填写正确的手机号");
      }
    },

    onHandleLogVerify() {
      this.$refs.logVerify.useVerify()
    },

     onGetCaptcha(params) {
        this.startCountDown();
    },

    getWxConfig() {
      let _this = this;
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中",
      });
      console.log(location.href, "location.href");
      getWxConfig({
        strUrl: location.href,
      })
        .then((res) => {
          console.log(res, "==========res1111111111111111111");
          this.signatureInfo = res.data;
          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,

            // appId: 'wxce10e023123fa835',
            // timestamp: '1628153404',
            // nonceStr: '79f94e8c-6dd3-47e7-8fdb-ec30c9275d7b',
            // signature: 'ea0155213fd4ef1a3bc1cb01a006ebf43f1b5e45',

            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
            openTagList: ["wx-open-launch-weapp"],
          });
          wx.ready(function (e) {
            console.log("WX.ready", e);
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，
            //config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
            //对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          });
          wx.error(function (e) {
            console.log("WX.error", e);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        })
        .finally((e) => {
          console.log("=========finally========", e);
          // this.getActiveDetail()
        });
    },
    // 抽奖状态查询
    toLotter() {
      return queryLotterStatus(this.id || this.acId)
        .then((res) => {
          console.log('是否开启抽奖', res);
          // 是否开启抽奖页
          if (res.errno == 0) {
            return true;
          } else {
            this.$toast(res.data.errmsg)
            return false;
          }
        })
        .catch((e) => {
          console.log("e--------", e);
          return false;
        });
    },
    getActiveDetail() {
      getActiveDetail({
        id: this.id || this.acId,
      })
        .then((res) => {
          const data = res.data;
          this.uploadImgStatus =
            data.uploadImgStatus !== undefined ? data.uploadImgStatus : 0;
          this.imgShowStatus = data.photoStatus;
          if (this.imgShowStatus == 0) {
            this.photoStatus = 0;
          } else if (this.imgShowStatus == 1) {
            this.photoStatus = 1;
          }
          this.photoNumber = data.photoNumber
          this.activityId = res.data.activityId;
          const keyString = 'ABCDEFGHIJ'
          keyString.split('').forEach((v) => {
            const activityFillingKey = 'activityFilling' + v

            if (data[activityFillingKey]) {
              this.activityFillings.push({
                id: nanoid(),
                label: data[activityFillingKey],
                required: +data[activityFillingKey + 'Check'] === 0,
                place: data[activityFillingKey + 'Content'],
                value:''
              })
            }
            const photoDescKey = 'photoDesc' + v
            if (data[photoDescKey]) {
              this.imgInfoList.push({
                id: nanoid(),
                content: data[photoDescKey],
                required: +data[photoDescKey + 'Check'] === 0,
                place: data[photoDescKey + 'Content'],
                value:''
              })
            }
          })
          this.imgTypeList = data.dtsFCommonParaConfigList

          this.detail = data || {};

          if (this.detail.status === "0") {
            this.$toast("活动尚未开始");
          } else if (this.detail.status === "2") {
            this.$toast("活动已经结束");
          } else {
            this.$toast.clear();
          }

          let vTitle = this.detail.activityName || "雅昌";
          document.title = vTitle;

          console.log("===id===", this.id,this.cartNumber);
          console.log("===title===", vTitle);
          window._dgt.push([
            "trackEvent",
            "shop_photography_competion",
            ["view_page", "photography_competition_code"],
            ["", this.id],
          ]);
          window._dgt.push([
            "trackEvent",
            "shop_photography_competion",
            ["view_page", "photography_competition_titile"],
            ["", vTitle],
          ]);
          window._dgt.push([
            "trackAttr",
            ["photography_competition_code"],
            [this.id],
          ]);
          window._dgt.push([
            "trackAttr",
            [" photography_competition_titile"],
            [vTitle],
          ]);
          // this.wxShare();
        })
        .catch((e) => {
          console.log("e", e);
          this.errmsg = e.errmsg;
          // this.$toast.clear();
        });
    },
    handleBeforeRead(file) {
      // console.log('handleBeforeRead', file)
      // if (Array.isArray(file)) {
      // 	for (let i = 0; i < file.length; i++) {
      // 		if (file[i].size > 20 * 1024 * 1024 || file[i].size < 3 * 808 * 808) {
      // 			this.$toast('请选择3M-20M之间大小的图片')
      // 			return false
      // 		}
      // 	}
      // } else {
      // 	if (file.size > 20 * 1024 * 1024 || file.size < 3 * 808 * 808) {
      // 		this.$toast('请选择3M-20M之间大小的图片')
      // 		return false
      // 	}
      // }
      return true;
    },
    handleAfterRead(file, info) {
      console.log("图片信息", file, info);
      if (Array.isArray(file)) {
        file.forEach((v) => {
          this.uploadFile(v);
        });
      } else {
        this.uploadFile(file);
      }
    },
    sleep(numberMillis) {
      var now = new Date();
      var exitTime = now.getTime() + numberMillis;
      while (true) {
        now = new Date();
        if (now.getTime() > exitTime) {
          console.log("执行了");
          return new Date().getTime();
        } else {
        }
      }
    },

    uploadFile(file) {
      const imageTyeps = ["image/png", "image/jpeg", "image/jpg"];
      const MIN_SIZE = 3 * 1024 * 1024;
      const MAX_SIZE = 20 * 1024 * 1024;

      if (!imageTyeps.includes(file.file.type)) {
        file.status = "failed";
        file.message = "格式不对";
        return;
      }

      // else if (file.file.size < MIN_SIZE) {
      // 	this.$toast('文件大小：', file.file.size + 'b')
      // 	file.status = 'failed'
      // 	file.message = '不能小于3M'
      // 	return
      // } else if (file.file.size > MAX_SIZE) {
      // 	this.$toast('文件大小：', file.file.size + 'b')
      // 	file.status = 'failed'
      // 	file.message = '不能大于20M'
      // 	return
      // }

      file.status = "uploading";
      file.message = "上传中...";

      let formData = new FormData();
      formData.append("file", file.file);
      formData.append("isActivity", "1");
      file.file.disabled = false;
      this.disabledGroupName = true;
      uploadImage(formData)
        .then((res) => {
          if (res.data.errno === 0) {
            file.status = "done";
            file.message = "上传成功";
            file.file.value = "";
            file.file.key = this.sleep(50);
            file.file.disabled = true;
            console.log("file", this.fileList);
            this.setImgStyle(this.uploadType);
            file.url = res.data.data.url;
            this.disabledGroupName = false;
            this.worksDisabled = false;
            console.log("imgShowStatus----:" + this.imgShowStatus);
            // if(this.uploadType == '1'){
            //    this.imgChangNameGroup();
            //    console.log('当前是图组上传形式',this.imgShowStatus)
            // }
          } else {
            file.status = "failed";
            file.message = res.data.errmsg;
          }
        })
        .catch((e) => {
          file.status = "failed";
          file.message = "上传失败";
          this.$toast.clear();
        });
    },
    // 判断作品项是否输入内容
    watchInput() {
      if(+this.photoStatus === 0){
        // 单图
        let list = this.imgList.filter(item=>{
          if(!item.id){
            item.imgInfoList.filter(item1 => {
              if(item1.required  && !item1.value){
                return item
              }
            })
          }
        })
        console.log(list);
        return list.length > 0
      }
      if(+this.photoStatus === 1){
        // 组图
        let list = this.imgInfoList.filter(item=>{
          if(item.required && !item.value){
            return item
          }
        })
        console.log(list);
        return list.length > 0
      }
    },

    async handleSubmit() {
      if (this.isUploadBtnDisabled) return

      let imgList = this.imgList.filter(item=>{
        if(!item.id){
          return item;
        }
      })

      const photoUploadMin = this.detail.photoUploadMin
      const imgLen = imgList.length
      console.log(imgList,'---------',this.photoNumber,imgLen % this.photoNumber);
      let uploadImgStatus = this.detail.uploadImgStatus
      //开启上传图片，配置最大打印数量 后 ，上传图片必须是 打印数量整数倍，否则禁止上传
      if( uploadImgStatus ===0  && this.photoNumber )  {
        if(!imgLen || imgLen % this.photoNumber){
          this.$toast(`上传作品数量必须是 ${this.photoNumber} 整数倍，当前是 ${imgLen}张作品`);
          return
        }
      }

      if (imgLen < photoUploadMin && this.detail.uploadImgStatus === 0) {
          const num = photoUploadMin - imgLen
          return this.$toast(`上传作品不得少于${photoUploadMin}张，还差${num}张作品`);
          // return this.$toast("请上传作品");
      }
      if ((this.isShowWorkDesc && this.isWorkDescRequired) && !this.workDescValue) {
         return this.$toast("请填写作品简介");
      }
      // 检测图片作品是否输入内容
      let isWorkInput = this.watchInput();
      if(isWorkInput && this.detail.uploadImgStatus === 0){
        return this.$toast("请填写图片信息");
      }
      if (!this.checked) {
        return this.$toast("请先确认成为Aphoto雅昌影像会员");
      }
      let params = {
        id: this.id,
        photoApplyType:this.detail.photoApplyType,
      };
      let letters = "ABCDEFGHIJQ";
      // 开启图片上传后，再检验是否输入图片名称
      let notInput  = this.activityFillings.find(item => {
        return item.required && !item.value
      });
      console.log('notInput',notInput);
      if (notInput) {
        return this.$toast(`请填写${notInput.label}`);
      }
      if (!this.isMobile()) {
        return this.$toast("请填写正确的手机号");
      }
      if (!this.isCode()) {
        return this.$toast("请填写正确的验证码");
      }
      this.activityFillings.forEach((v, i) => {
        const key = "activityFilling" + letters[i] + letters[i];
        params[key] = v.value;
      });
      params.mobile = this.mobile;
      params.code = this.code;
      params.a = this.id || this.acId;
      if(this.channelId || this.c){
        params.c = this.channelId || this.c;
      }
      params.d = this.d;
      params.id = this.id || this.acId;
      params.photoStatus = this.photoStatus
     params.workDescValue = this.workDescValue
      const photoTypeList = []
      //把图片数据组合成提交数据
      if(this.photoStatus === 0){
        // 单张数据组合
        imgList.forEach((img)=>{
          const obj = {
            image: img.image,
            photoType: img.photoType,
          };
          img.imgInfoList.forEach((v, i) => {
            const key = "photoDesc" + letters[i] + letters[i];
            obj[key] = v.value;
          });
          photoTypeList.push(obj)
        })
      } else if(this.photoStatus === 1){
        // 组图数据组合
        const obj = {
            photoType: this.photoType,
          };
        this.imgInfoList.forEach((v, i) => {
            const key = "photoDesc" + letters[i] + letters[i];
            obj[key] = v.value;
        });
        imgList.forEach((img)=>{
          const obj1 = JSON.parse(JSON.stringify(obj))
          obj1.image = img.image,
          photoTypeList.push(obj1)
        })
      }
      params.photoTypeList = photoTypeList;
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });
      submitActiveInfo(params).then(async (res) => {
        console.log(res);
        this.$toast.clear();
        const photoApplyType = Number(this.detail.photoApplyType);
        params.userId = res.data.userId;
        this.setReqData(params); // 埋点
        this.cartNumber = res.data.cartNumber || ''
        console.log(photoApplyType)
        switch(photoApplyType){
          case 0:
            // 有礼品
            this.$toast("提交成功");
            this.showCouponToMini = true
            break;
          case 1:
            // 无礼品
            this.$toast("报名成功");
            break;
          case 2:
            // 抽奖
            this.$toast("提交成功");
            let isLottery = await this.toLotter();
            if (isLottery) {
              this.lottery();
            };
            break;
          case 3:
          case 4:
            // 跳转商品
            this.userId = res.data.userId
            this.applyId = res.data.applyId
            this.isSuccess = true
            break;
        }
      }).catch(error =>{
        console.log(error);
      });
    },

    setReqData(query) {
      var reqData = {};
      if (query.a) {
        reqData.acId = query.acId;
      };
      reqData.sourceType = 3;
      if (query.c) {
        reqData.channelManageId = query.c;
      }
      reqData.userId = query.userId;
       saveUserChannelInfoById(reqData).then((res)=>{
         console.log('res',res)
       })
    },
    wxShare() {
      //分享到朋友圈
      console.log("this.detail", this.detail);
      this.$wx.onMenuShareTimeline({
        title: this.detail.shareMainTitle, // 分享标题
        link: window.location.href,
        imgUrl: this.detail.sharePresentation, // 分享图标
        success: function () {
          // 分享成功执行此回调函数
        },
        cancel: function () {},
      });

      //分享给朋友
      this.$wx.onMenuShareAppMessage({
        title: this.detail.shareMainTitle, // 分享标题
        desc: this.detail.shareSubtitles,
        link: window.location.href,
        imgUrl: this.detail.sharePresentation, // 分享图标
        trigger: function (res) {},
        success: function (res) {},
        cancel: function (res) {},
        fail: function (res) {},
      });
    },
    successTo(){
      this.setNum = this.imgList.length;
      if(+this.detail.isPlaceOrder === 2){
        this.detail.isPlaceOrder = 1
      }
      const {photoApplyType,popoverImageUrl} = this.detail;
      console.log("photoApplyType",photoApplyType)
      console.log("popoverImageUrl",popoverImageUrl)
      if (photoApplyType == 4 && popoverImageUrl){
        this.popoverImageUrl = popoverImageUrl;
        return this.isSupernatantBg = true;
      }
      if (isPc()) {
        window.location.href = `${process.env.VUE_APP_PC_URL}?id=${this.detail.skipGoodsId}&num=${this.setNum}&a=${this.a}&c=${this.channelId || this.c}&source=${this.a}&channelId=${this.channelId || this.c}&acId=${this.acId || this.id}&applyId=${this.applyId}&isPlaceOrder=${this.detail.isPlaceOrder}&isUpdate=${this.applyStatus}&cartNumber=${this.cartNumber}`
      } else {
        this.toMiniApp()
      }
    },
    toMiniApp(){
      const MINI_APP_URL = "/pagesA/commodityDetails/commodityDetails"
      const linkParams = this.getUrlObj(decodeURIComponent(window.location.href));
      let linkUrl;
      if(linkParams.linkCode){
        linkUrl = `${MINI_APP_URL}&linkCode=${linkParams.linkCode}&id=${this.detail.skipGoodsId}&num=${this.setNum}&a=${this.a}&c=${this.channelId || this.c}&source=${this.a}&userId=${this.userId}&applyId=${this.applyId}&channelId=${this.channelId || this.c}&acId=${this.acId || this.id}&isPlaceOrder=${this.detail.isPlaceOrder}&isUpdate=${this.applyStatus}&cartNumber=${this.cartNumber}`
      }else {
        linkUrl = `${MINI_APP_URL}&id=${this.detail.skipGoodsId}&num=${this.setNum}&a=${this.a}&c=${this.channelId || this.c}&source=${this.a}&userId=${this.userId}&applyId=${this.applyId}&channelId=${this.channelId || this.c}&acId=${this.acId || this.id}&isPlaceOrder=${this.detail.isPlaceOrder}&isUpdate=${this.applyStatus}&cartNumber=${this.cartNumber}`
      }
      let params = {
        a: this.id || this.acId,
        activityId: this.id || this.acId,
        linkUrl,
        mobile: this.mobile,
        commodityType: 0,
      };
      getUrlLink(params)
        .then((res) => {
          window.location.href = res.data.url_link;
        })
        .catch((e) => {
          this.$toast('跳转失败')
          return false;
        });
    }
  },
};
</script>

<style lang="scss" >
.desc1 {
  font-size: 12px;
  font-weight: 400;
  color: #181818;
  margin-top: 10px;
}

.my-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.flex-r-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-c-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload {
  width: 335px;
  height: 50px;
  background: #d60015;
  border-radius: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: 20px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  input {
    width: 114px;
    height: 114px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;
  }
}

.dsc {
  display: flex;
  margin-bottom: 30px;

  .ysc {
    font-size: 13px;
    color: #181818;
    margin-right: 10px;

    span {
      color: #d60015;
    }
  }

  .login {
    font-size: 13px;
    text-decoration: underline;
    color: #181818;
  }
}

.van-uploader__preview-image {
  display: block;
  width: 2.53333rem !important;
  height: 2.53333rem !important;
}

.van-uploader__upload {
  width: 2.53333rem !important;
  height: 2.53333rem !important;
}

.group-input {
  height: 0.90667rem;
  margin-left: 0.2rem;
  margin-bottom: 0.15rem;
  padding: 0 0.32rem;
  font-size: 0.32rem;
  color: #1f2429;
  background: #f3f4f5;
  border-radius: 0.10667rem;
  outline: none;
  border: 0;
}

.btn-go-home {
  width: 200px;
  border: none;
  background: #d82e4a;
  border-radius: 5px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  height: 35px;
  line-height: 35px;
}

.radioInput {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  line-height: 20px;
  float: left;
  margin-top: 10px;
}

.radioMsg {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  float: left;
  color: #d32121;
}

.popCon {
  .tip {
    font-size: 16px;
    padding: 20px 20px 25px;
    text-align: center;
    line-height: 25px;
    color: #d82e4a;
  }

  .btnContainer {
    // width: 100%;
    // position: relative;
    display: flex;
    justify-content: center;
  }
}

#launch-btn {}

.home {
  .imgs-contont {
    width: 100%;
    position: absolute;
    top: 106%;

    input {
      width: 100%;
    }
  }

  .erroMsg {
    text-align: center;
    // position: absolute;
    top: 30%;
    left: 33%;
  }

  &-banner {
    width: 375px;
    // vertical-align: middle;
  }

  &-content {
    padding: 17px;

    &__title {
      // color: #F7CF9E;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
    }

    &__carousel {
      margin-top: 10px;
    }

    &__detail {
      max-width: 375px;
      overflow: hidden;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;

      img {
        width: 375px !important;
        height: auto !important;
      }
    }

    &__form {
      // margin-top: 24px;
      padding: 16px;
    }

    &__formitem {
      margin-bottom: 22px;

      .input_textarea {
        height: 121px
      }
    }

    &__formlabel {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #222222;
      line-height: 20px;
      margin-bottom: 8px;

      span {
        font-size: 11px;
        color: #c9cace;
      }
    }

    .upload-tip {
      font-size: 11px;
      color: #c9cace;
      margin-top: 0.1rem;
    }

    &__forminput,
    &__code {
      width: 340px;
      height: 44px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #222222;
      outline: none;
      font-size: 16px;
      padding-left: 16px;
      padding-right: 16px;
    }

    &__code {
      width: 220px;
    }

    &__code-btn {
      width: 105px;
      height: 44px;
      background: #D60015;
      border-radius: 4px;
      margin-left: 15px;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
    }

    &__code-btn-dis {
      background: #999999;
    }

    &__formupload {
      margin-top: 8px;
      border-radius: 4px;
    }

    &__logo {
      display: block;
      width: 150px;
      margin: 32px auto;
    }

    &__btn {
      margin-top: 16px;
      width: 340px !important;
    }

    &__sendcode {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .home-content__forminput {
        flex: 1;
        margin-right: 20px;
        margin-bottom: 0;
        margin-top: 0;
      }

    }
  }
  .supernatant-bg{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    //overflow: hidden;
    .close-supernatant-bg{
      position: absolute;
      top: 50px;
      right: 28px;
      width: 20px;
      height: 20px;
    }
    .supernatant-bg-result{
      width: 319px;
      height: 419px;
      -webkit-touch-callout: default;
    }
  }
}
</style>
