<template>
  <div class="img-list-body">
    <div class="img-list">
      <img-item
        class="img-item"
        v-for="(item, index) in list"
        :item="item"
        :index="index"
        :key="index"
        :isShowSelect="false"
        v-bind="$attrs"
        @delete="deleteItem"
      ></img-item>
    </div>
    <div class="img-from" v-if="list.length > 0">
      <my-select
        v-bind="$attrs"
        :is-more="true"
        v-bind:select-val.sync="photoType"
        v-if="imgTypeList && imgTypeList.length > 0"
        :img-type-list="imgTypeList"
      >
      </my-select>
      <input
        v-for="imgInfo in imgInfoList"
        :key="imgInfo.id"
        class="img-input"
        v-model="imgInfo.value"
        :placeholder="imgInfo.content || '请输入'"
        maxlength="50"
      />
    </div>
  </div>
</template>
<script>
import ImgItem from '@components/ImgItem.vue'
import mySelect from './Select.vue'
export default {
  name: 'ImgList1',
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    imgInfoList: {
      type: Array,
      default() {
        return []
      }
    },
    imgTypeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      photoType: ''
    }
  },
  components: { ImgItem, mySelect },
  watch: {
    photoType(newValue) {
      this.$emit('updatePhotoType', newValue)
    }
  },
  methods: {
    deleteItem(index) {
      this.list.splice(index, 1)
    }
  }
}
</script>
<style scoped>
.img-list-body {
  margin-bottom: 10px;
}
.img-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}
.img-item {
  margin-left: 11px;
}
.img-from {
  display: flex;
  flex-direction: column;
  margin: 0 12px;
}

.img-input {
  width: 100%;
  height: 30px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #c9c9c9;
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 10px;
}
</style>
