<!-- 通过手机号进行登录 -->
<template>
  <div v-if="show">
    <LogVerify ref="logVerify" :mobile="phone" @getCaptcha="onGetCaptcha" />

    <div class="shade"></div>
    <div class="content">
      <div class="modal">
        <img
          src="../assets/images/icon-close1.png"
          class="close"
          alt="关闭"
          @click="handleClose"
        />
        <div class="form-item">
          <div class="label">手机号</div>
          <input
            type="text"
            maxlength="11"
            placeholder="请输入手机号"
            v-model="phone"
          />
        </div>
        <div class="form-item">
          <div class="label">验证码</div>
          <div class="code">
            <input
              type="text"
              maxlength="6"
              placeholder="请输入验证码"
              v-model="code"
            />
            <div
              v-if="sendStatus === 0"
              class="get-code"
              @click.stop="getCode"
            >
              获取验证码
            </div>
            <div v-if="sendStatus === 1" class="get-code">
              发送中({{ seconds }}s)
            </div>
            <div
              v-if="sendStatus === 2"
              class="get-code"
              @click.stop="getCode"
            >
              重新发送
            </div>
          </div>
        </div>
        <div class="btn" @click="handleSubmit">提交</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCaptcha,
  getActivityPhotographyUserImg
} from '@/api/home'

import LogVerify from '@/components/LogVerify/LogVerify'

export default {
  name: 'LoginPhone',

  components: { LogVerify },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    activityId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      sendStatus: 0,
      seconds: 60,
      phone: '',
      code: ''
    }
  },
  methods: {
    getCode() {
      if (this.isMobile()) {
        this.onHandleLogVerify()
      } else {
        this.$toast('请填写正确的手机号')
      }
    },

    onHandleLogVerify() {
      this.$refs.logVerify.useVerify()
    },

    onGetCaptcha(params) {
      this.startCountDown()
    },

    startCountDown() {
      if (this.timer) return
      this.sendStatus = 1
      this.timer = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--
        } else {
          this.clearTimer()
        }
      }, 1000)
    },
    clearTimer() {
      clearInterval(this.timer)
      this.timer = null
      this.seconds = 60
      this.sendStatus = 2
    },
    handleClose() {
      this.$emit('close', false)
    },
    handleSubmit() {
      if (!this.isMobile()) {
        return this.$toast('请输入手机号')
      }
      if (!this.isCode()) {
        return this.$toast('请输入验证码')
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 6000
      })
      getActivityPhotographyUserImg({
        id: this.activityId,
        code: this.code,
        mobile: this.phone
      }).then(res => {
        if (res.errno === 0) {
          // 成功
          this.$toast.clear()
          const result = res.data
          this.phone = ''
          this.code = ''
          clearInterval(this.timer)
          this.timer = null
          this.seconds = 60
          this.sendStatus = 0
          if (!result || !result.images) {
            this.$emit('close', false)
            return this.$toast('该手机号还没有上传作品！')
          }
          console.log('result :>> ', result)
          this.$emit('success', result)
        } else {
          this.$toast(res.errmsg)
        }
      })
    },
    isMobile() {
      const flag = /^1[0-9]{10}$/.test(this.phone)
      return flag
    },
    isCode() {
      const flag = /^[0-9]{6}$/.test(this.code)
      return flag
    }
  }
}
</script>
<style scoped>
.shade {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9;
  overflow: hidden;
}
.content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.close {
  position: absolute;
  width: 25px;
  height: 25px;
  margin-bottom: 14px;
  top: -39px;
  right: -12.5px;
}
.modal {
  position: relative;
  width: 320px;
  height: 240px;
  background: #ededed;
  border-radius: 4px;
  padding: 20px 17px 15px;
}
.form-item {
  margin-bottom: 12px;
  width: 317px;
}
.form-item .label {
  color: #242424;
  font-size: 12px;
  margin-bottom: 6px;
}
.form-item input {
  height: 38px;
  width: 287px;
  border: none;
  outline: none;
  border-radius: 2px;
  line-height: 38px;
  display: block;
  padding-left: 9px;
  font-size: 12px;
  border: 1px solid #c0c0c0;
}
.code {
  display: flex;
  align-items: center;
}
.code input {
  width: 177px;
}
.code .get-code {
  background: #d82e4a;
  font-size: 13px;
  min-width: 100px;
  height: 38px;
  margin-left: 10px;
  text-align: center;
  line-height: 38px;
  color: white;
  border-radius: 2px;
}
.btn {
  margin-top: 22px;
  width: 287px;
  height: 38px;
  background: #d82e4a;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  line-height: 38px;
  text-align: center;
}
</style>
