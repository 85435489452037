import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentGoods: {},
    currentAddress: {},
    userInfo: {}
  },
  mutations: {
    commitCurrentGoods(state, value) {
      state.currentGoods = value
    },
    commitCurrentAddress(state, value) {
      state.currentAddress = value
    },
    commitUserInfo(state, value) {
      state.userInfo = value
    }
  },
  getters: {
    address: state => state.currentAddress
  },
  actions: {},
  modules: {}
})
