<!-- 通过手机号进行登录 -->
<template>
  <div v-if="value">
    <div class="shade"></div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CommonDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style scoped>
.shade {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9;
  overflow: hidden;
}
.content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

</style>
