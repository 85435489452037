<template>
  <div class="img-item">
    <img :src="item.image" alt="" class="img" />
    <img
      src="../assets/images/icon-delete.png"
      v-if="!isDisabled"
      class="close"
      @click.stop="deleteItem"
      alt=""
    />
    <div class="img-from" v-if="isShowSelect">
      <my-select
        v-bind="$attrs"
        v-if="imgTypeList && imgTypeList.length > 0"
        :img-type-list="imgTypeList"
        v-bind:select-val.sync="item.photoType"
        :disabled="isDisabled"
      ></my-select>
      <input
        v-for="imgInfo in item.imgInfoList"
        :key="imgInfo.id"
        class="img-input"
        v-model="imgInfo.value"
        :placeholder="imgInfo.content || '请输入'"
        maxlength="50"
        :disabled="isDisabled"
      />
    </div>
  </div>
</template>
<script>
import mySelect from './Select.vue'

export default {
  name: 'ImgItem',
  components: { mySelect },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    isShowSelect: {
      type: Boolean,
      default: true
    },
    imgTypeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    isDisabled() {
      return !!this.item.id
    }
  },
  methods: {
    deleteItem() {
      this.$emit('delete', this.index)
    }
  }
}
</script>

<style scoped>
.img-item {
  display: flex;
  margin-bottom: 14px;
  position: relative;
}
.close {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 84px;
  z-index: 2;
}
.img {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border-radius: 4px;
  background-color: #ccc;
}
.img-name {
  font-size: 10px;
}
.img-from {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.img-input {
  width: 218px;
  height: 30px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #c9c9c9;
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 10px;
}
</style>
