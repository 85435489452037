<template>
  <div class="upload-loading-wrapper" v-if="isShow">
    <section class="loading-top">
      <div class="loading">
        <div class="animation">
        </div>

        <div class="num">
          {{ progress }}
        </div>
      </div>
    </section>

    <section class="loading-text">
      <span>上传中，请耐心等待~</span>
    </section>
  </div>
</template>

<script>
export default {
  name: 'UploadLoading',

  props: {
    uploadingInfo: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      isShow: false
    }
  },

  computed: {
    progress() {
      const { accordantImageNumber, uploaded } = this.uploadingInfo
      return `${uploaded}/${accordantImageNumber}`
    }
  },

  methods: {
    open() {
      this.isShow = true
    },

    close() {
      this.isShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-loading-wrapper {
  font-family: PingFangSC-Medium, PingFang SC;
  width: 112px; height:112px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, .6);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 20;

  .loading-top {
    position: relative;
    .loading {
      .animation {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: conic-gradient(transparent, #D60015);
        --mask: radial-gradient(closest-side, transparent 75%, black 76%);
        -webkit-mask-image: var(--mask);
        mask-image: var(--mask);
        animation: spin 1s linear infinite;
      }

      .num {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(calc(8/12));
        font-weight: 500;
        color: #E6E9F0;
      }
    }
  }

  .loading-text {
    font-size: 10px;
    font-weight: 500;
    color: #F1F5F9;
    transform: scale(0.85);
    white-space: nowrap;
    margin-top: 12px;
  }
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
</style>
