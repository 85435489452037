<template>
  <div class="img-list">
    <img-item
      v-for="(item, index) in list"
      :item="item"
      :index="index"
      :key="index"
      v-bind="$attrs"
      @delete="deleteItem"
    ></img-item>
  </div>
</template>
<script>
import ImgItem from '@components/ImgItem.vue'
export default {
  name: 'ImgList',
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: { ImgItem },
  methods: {
    deleteItem(index) {
      this.list.splice(index, 1)
    }
  }
}
</script>
<style scoped>
.img-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
